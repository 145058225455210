/* You can add global styles to this file, and also import other style files */
/* h1 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 250%;
}

h2, h3 {
  color: #444;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
} */

body {
  /* height: auto; */
  margin: 0;
  background-image: url("./assets/images/backgrounds/background2_light.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  /* width: 100%; */
  /* height: 100vh; */
}
/* 
body, input[type="text"], button {
  color: #333;
  font-family: Cambria, Georgia, serif;
} */

/* everywhere else */
/* * {
  font-family: Arial, Helvetica, sans-serif;
} */